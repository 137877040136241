import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './GlobalStyles.css'; // arquivo CSS separado para melhor organização
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL

const Login = () => {
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState(''); 
  const [mostrarSenha, setMostrarSenha] = useState(false); 
  const [error, setError] = useState(''); 
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('Tentando login com:', { login, senha });
  
    setError('');
  
    try {
      if (!API_URL) {
        throw new Error('A URL da API não está configurada corretamente.');
      }
  
      const response = await axios.post(`${API_URL}/api/login`, { login, senha });
      const token = response.data.token;
      
      // Armazena o token no localStorage
      localStorage.setItem('token', token);
  
      // Decodifica o token para acessar o 'role'
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
  
      // Redireciona de acordo com o 'role' do usuário
      if (userRole === 'admin') {
        console.log('Login de admin bem-sucedido, redirecionando para o painel de admin');
        navigate('/admin'); // Rota para o dashboard de admin
      } else {
        console.log('Login de usuário comum bem-sucedido, redirecionando para o painel de usuário');
        navigate('/dashboard'); // Rota para o dashboard de usuário
      }
    } catch (err) {
      let errorMessage = 'Erro ao tentar fazer login. Tente novamente mais tarde.';
  
      if (err.response) {
        if (err.response.status === 401) {
          errorMessage = 'Login ou senha incorretos';
        } else if (err.response.status === 404) {
          errorMessage = 'Usuário não encontrado.';
        } else {
          console.error('Erro no login:', err.response.data);
        }
      } else if (err.request) {
        console.error('Erro de rede:', err.request);
        errorMessage = 'Erro de rede. Verifique sua conexão.';
      } else {
        console.error('Erro na configuração do login:', err.message);
      }
  
      setError(errorMessage);
    }
  };  

  return (
    <div className="container">
      <div className="box">
        <h1 className="title">W-GAMES CURITIBA</h1>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <input
              type="text"
              id="login"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              placeholder="Digite seu usuário"
              required
            />
          </div>
          <div className="input-group">
            <div style={{ position: 'relative' }}>
              <input
                type={mostrarSenha ? 'text' : 'password'}
                id="senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                placeholder="Digite sua senha"
                required
              />
              <span
                onClick={() => setMostrarSenha(!mostrarSenha)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  color: mostrarSenha ? '#0072ff' : 'rgba(0, 114, 255, 0.5)',
                }}
              >
                👁️
              </span>
            </div>
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="button">
            Entrar
          </button>
        </form>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '100px' }}>
          <button
            type="button"
            className="button-cadastrar"
            onClick={() => navigate('/cadastro-cliente')}
          >
            Cadastrar
          </button>
          <button
            type="button"
            className="button-esqueci"
            onClick={() => navigate('/esqueci-senha')}
          >
            Esqueci a Senha
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
