import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './GlobalStyles.css';

const API_URL = `${process.env.REACT_APP_API_URL}`;

console.log('API URL:', API_URL); // Log da API URL

// Lista de DDDs válidos do Brasil
const validDDDs = [
  '11', '12', '13', '14', '15', '16', '17', '18', '19', '21', '22', '24', '27', '28',
  '31', '32', '33', '34', '35', '37', '38', '41', '42', '43', '44', '45', '46', '47', '48', '49',
  '51', '53', '54', '55', '61', '62', '63', '64', '65', '66', '67', '68', '69', '71', '73', '74',
  '75', '77', '79', '81', '82', '83', '84', '85', '86', '87', '88', '89', '91', '92', '93', '94',
  '95', '96', '97', '98', '99'
];

// Função para validar CPF
function isValidCPF(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  let result = true;
  [9, 10].forEach(function(j) {
      let soma = 0, r;
      cpf.split(/(?=)/).splice(0, j).forEach(function(e, i) {
          soma += parseInt(e) * ((j + 2) - (i + 1));
      });
      r = soma % 11;
      r = (r < 2) ? 0 : 11 - r;
      if (r !== parseInt(cpf.substring(j, j + 1))) result = false;
  });
  return result;
}

const CadastroCliente = () => {
  const navigate = useNavigate();
  const [cliente, setCliente] = useState({
    nome: '',
    sobrenome: '',
    cpf: '',
    telefone: '',
    email: '',
    senha: '',
    confirmarEmail: '',
    confirmarSenha: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [mostrarConfirmaSenha, setMostrarConfirmaSenha] = useState(false);

  const handleChange = (e) => {
    setCliente({ ...cliente, [e.target.name]: e.target.value });
    setError(''); // Reseta o erro ao digitar
  };

  const formatPhone = (phone) => {
    return phone.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  const isValidDDD = (phone) => {
    const ddd = phone.slice(0, 2);
    return validDDDs.includes(ddd);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidCPF(cliente.cpf)) {
      setError('CPF inválido.');
      return;
    }

    const formattedPhone = cliente.telefone.replace(/\D/g, '');

    if (!isValidDDD(formattedPhone)) {
      setError('DDD inválido.'); // Verificação do DDD
      return;
    }

    if (formattedPhone.length !== 11) {
      setError('Telefone deve ter 11 dígitos.');
      return;
    }

    if (cliente.email !== cliente.confirmarEmail) {
      setError('Os emails não coincidem.');
      return;
    }

    if (cliente.senha !== cliente.confirmarSenha) {
      setError('As senhas não coincidem.');
      return;
    }

    setLoading(true);
  try {
    console.log('API URL:', process.env.REACT_APP_API_URL);
    const url = `${process.env.REACT_APP_API_URL}/api/clientes/verificar`;
    console.log('URL construída:', url); // Aqui você loga a URL construída

    // Verifica se o CPF ou o email já estão cadastrados
    console.log('Verificando CPF e Email:', { cpf: cliente.cpf, email: cliente.email });
    const checkResponse = await axios.post(url, {
      cpf: cliente.cpf,
      email: cliente.email,
    });

      console.log('Resposta da verificação:', checkResponse.data); // Log da resposta da verificação

      if (checkResponse.data.exists) {
        if (checkResponse.data.cpfExists) {
          setError('CPF já cadastrado.');
        }
        if (checkResponse.data.emailExists) {
          setError('Email já cadastrado.');
        }
        return;
      }

      // Cadastro do cliente
      console.log('Cadastrando cliente:', cliente);
      await axios.post(`${API_URL}/api/clientes`, {
        nome: cliente.nome,
        sobrenome: cliente.sobrenome,
        cpf: cliente.cpf,
        telefone: formattedPhone,
        email: cliente.email,
        senha: cliente.senha,
        role: 'user'
      });
      alert('Cliente cadastrado com sucesso! Faça o Login.');
      navigate('/');
    } catch (error) {
      console.error('Erro ao cadastrar cliente:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : 'Erro ao cadastrar cliente');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="box">
        <h1 className="title">Cadastro de Cliente</h1>
        {error && <div className="error">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              name="nome"
              value={cliente.nome}
              onChange={handleChange}
              placeholder="Nome"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              name="sobrenome"
              value={cliente.sobrenome}
              onChange={handleChange}
              placeholder="Sobrenome"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              name="cpf"
              value={cliente.cpf}
              onChange={handleChange}
              placeholder="CPF"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              name="telefone"
              value={formatPhone(cliente.telefone)}
              onChange={handleChange}
              placeholder="Telefone (DDD + Número)"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              name="email"
              value={cliente.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              name="confirmarEmail"
              value={cliente.confirmarEmail}
              onChange={handleChange}
              placeholder="Confirmar Email"
              required
            />
          </div>
          <div className="input-group">
            <div style={{ position: 'relative' }}>
              <input
                type={mostrarSenha ? 'text' : 'password'}
                name="senha"
                value={cliente.senha}
                onChange={handleChange}
                placeholder="Senha"
                required
              />
              <span
                onClick={() => setMostrarSenha(!mostrarSenha)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  color: mostrarSenha ? '#0072ff' : 'rgba(0, 114, 255, 0.5)',
                }}
              >
                👁️
              </span>
            </div>
          </div>
          <div className="input-group">
            <div style={{ position: 'relative' }}>
              <input
                type={mostrarConfirmaSenha ? 'text' : 'password'}
                name="confirmarSenha"
                value={cliente.confirmarSenha}
                onChange={handleChange}
                placeholder="Confirmar Senha"
                required
              />
              <span
                onClick={() => setMostrarConfirmaSenha(!mostrarConfirmaSenha)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  fontSize: '1.2rem',
                  color: mostrarConfirmaSenha ? '#0072ff' : 'rgba(0, 114, 255, 0.5)',
                }}
              >
                👁️
              </span>
            </div>
          </div>
          {error && <div className="error">{error}</div>}
          <button type="submit" className="button" disabled={loading}>
            {loading ? 'Cadastrando...' : 'Cadastrar'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CadastroCliente;