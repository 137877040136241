import { useEffect, useState } from 'react';
import './DashboardAdmin.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function GerenciarEquipamentos() {
    const [searchInput, setSearchInput] = useState('');
    const [clienteData, setClienteData] = useState(null);
    const [equipamentos, setEquipamentos] = useState([]);
    const [ordens, setOrdens] = useState([]);
    const [ordemExibida, setOrdemExibida] = useState(null);
    const [novoEquipamento, setNovoEquipamento] = useState('');
    const [adicionarMensagem, setAdicionarMensagem] = useState('');
    const [ordemData, setOrdemData] = useState({
        defeito_reclamado: '',
        provavel_causa: '',
        orcamento: '',
        status: 'Em análise', // Default status
        observacoes: '',
        data_entrada: new Date().toISOString(),
        data_saida: null,  // Novo campo
        fotos: null,  // Novo campo
    });
    const [selectedEquipamento, setSelectedEquipamento] = useState(null);
    const [tiposEquipamentos, setTiposEquipamentos] = useState([]);

    useEffect(() => {
        const fetchTiposEquipamentos = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/equipamentos/tipos`);
                setTiposEquipamentos(response.data);
            } catch (error) {
                console.error('Erro ao buscar tipos de equipamentos:', error);
            }
        };
        fetchTiposEquipamentos();
    }, []);

    const buscarCliente = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/clientes`, {
                params: { cpfOuTelefone: searchInput },
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setClienteData(response.data);
            buscarEquipamentos(response.data.id);
        } catch (error) {
            console.error('Erro ao buscar cliente:', error);
        }
    };

    const buscarEquipamentos = async (clienteId) => {
        try {
            const response = await axios.get(`${API_URL}/api/clientes/${clienteId}/equipamentos`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setEquipamentos(response.data);
        } catch (error) {
            console.error('Erro ao buscar equipamentos:', error);
        }
    };

    const buscarOrdens = async (equipamentoId) => {
        try {
            const response = await axios.get(`${API_URL}/api/equipamentos/${equipamentoId}/ordens`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setOrdens(response.data);
        } catch (error) {
            console.error('Erro ao buscar ordens:', error);
        }
    };

    const handleSelecionarEquipamento = (equipamentoId) => {
        setSelectedEquipamento(equipamentoId);
        buscarOrdens(equipamentoId);
    };

    const adicionarEquipamento = async () => {
        if (!clienteData || !novoEquipamento) return;
        try {
            await axios.post(`${API_URL}/api/clientes/${clienteData.id}/equipamentos`, {
                tipo_equipamento: novoEquipamento
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            buscarEquipamentos(clienteData.id);
            setNovoEquipamento('');
            setAdicionarMensagem('Equipamento adicionado com sucesso!');
        } catch (error) {
            console.error('Erro ao adicionar equipamento:', error);
            setAdicionarMensagem('Erro ao adicionar equipamento');
        }
    };

    const criarOrdem = async () => {
        if (!selectedEquipamento || !ordemData.defeito_reclamado) return;
    
        try {
            // Se o campo status estiver vazio ou inválido, defina um valor padrão
            const statusValido = ['Em análise', 'Aguardando aprovação', 'Aprovado', 'Reprovado'];
            const status = statusValido.includes(ordemData.status) ? ordemData.status : 'Em análise';
    
            // Formatação dos dados antes de enviar
            const ordemDataFormatada = {
                ...ordemData,
                status,  // Garantindo que o status seja um valor válido
                orcamento: ordemData.orcamento === '' ? null : parseFloat(ordemData.orcamento),
                data_entrada: new Date().toISOString(),
            };
    
            // Enviar dados da ordem para a API
            await axios.post(`${API_URL}/api/equipamentos/${selectedEquipamento}/ordens`, ordemDataFormatada, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
    
            alert('Ordem criada com sucesso!');
            
            // Resetar dados do formulário
            setOrdemData({
                defeito_reclamado: '',
                provavel_causa: '',
                orcamento: '',
                status: 'Em análise', 
                observacoes: '',
                data_entrada: new Date().toISOString(),
                data_saida: null,
                fotos: null,
            });
    
            carregarOrdens();
        } catch (error) {
            console.error('Erro ao criar ordem:', error);
        }
    };    
    

    // Formatação do valor do orçamento
    const handleOrcamentoChange = (e) => {
        // Remove caracteres não numéricos
        const value = e.target.value.replace(/[^0-9]/g, '');
        // Converte para float e divide por 100
        const orcamento = parseFloat(value) / 100;
        // Define o orcamento com duas casas decimais ou string vazia se NaN
        setOrdemData({ 
            ...ordemData, 
            orcamento: isNaN(orcamento) ? '' : orcamento.toFixed(2) 
        });
    };

    // Carrega as ordens de um equipamento
    const carregarOrdens = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/equipamentos/${selectedEquipamento}/ordens`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setOrdens(response.data);
        } catch (error) {
            console.error('Erro ao carregar ordens:', error);
        }
    };

    // Formata a data para o fuso horário de Brasília
    const formatarDataBrasilia = (dataISO) => {
        const data = new Date(dataISO);
        return data.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    };

    // Manipula a exibição de uma ordem selecionada
    const exibirOrdem = (ordemId) => {
        if (ordemId === ordemExibida) {
            setOrdemExibida(null); // Se a mesma ordem for clicada, ocultá-la
        } else {
            setOrdemExibida(ordemId);
        }
    };

    return (
        <div className="dashboard-admin">
            <h2 style={{ textAlign: 'center' }}>Gerenciar Equipamentos</h2>

            {/* Seção de Busca */}
            <div className="search-section">
                <input 
                    type="text" 
                    placeholder="Buscar por CPF ou Telefone" 
                    value={searchInput} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <button onClick={buscarCliente}>Buscar</button>
            </div>

            {/* Informações do Cliente */}
            {clienteData && (
                <div className="cliente-info">
                    <h3>Cliente Selecionado</h3>
                    <p>Nome: {clienteData.nome} {clienteData.sobrenome}</p>
                    <p>Telefone: {clienteData.telefone}</p>
                    <p>CPF: {clienteData.cpf}</p>
                    <p>Email: {clienteData.email}</p>
                </div>
            )}

            {/* Lista de Equipamentos */}
            {equipamentos.length > 0 && (
                <div className="equipamentos-section">
                    <h4>Equipamentos do Cliente</h4>
                    {equipamentos.map((equipamento) => (
                        <div 
                            key={equipamento.id} 
                            onClick={() => handleSelecionarEquipamento(equipamento.id)}
                            className={selectedEquipamento === equipamento.id ? 'selected' : ''}
                        >
                            {equipamento.tipo_equipamento}
                        </div>
                    ))}
                </div>
            )}

            {/* Lista de Ordens com Expansão Individual */}
            {ordens.map((ordem) => (
                <div key={ordem.id} style={{ marginBottom: '10px' }}>
                    <button onClick={() => exibirOrdem(ordem.id)}>
                        Ordem #{ordem.id} - {formatarDataBrasilia(ordem.data_entrada)}
                    </button>
                    {ordemExibida === ordem.id && (
                        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', marginTop: '5px' }}>
                            <p><strong>Defeito Reclamado:</strong> {ordem.defeito_reclamado}</p>
                            <p><strong>Provável Causa:</strong> {ordem.provavel_causa}</p>
                            <p><strong>Orçamento:</strong> R$ {parseFloat(ordem.orcamento).toFixed(2)}</p>
                            <p><strong>Status:</strong> {ordem.status}</p>
                            <p><strong>Observações:</strong> {ordem.observacoes}</p>
                        </div>
                    )}
                </div>
            ))}

            {/* Adicionar Equipamento */}
            {clienteData && (
                <div className="adicionar-equipamento">
                    <h4>Adicionar Equipamento</h4>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <select 
                            value={novoEquipamento} 
                            onChange={(e) => setNovoEquipamento(e.target.value)}
                            style={{ marginBottom: '5px' }}
                        >
                            <option value="">Selecione o equipamento</option>
                            {tiposEquipamentos.map((tipo, index) => (
                                <option key={index} value={tipo}>{tipo}</option>
                            ))}
                        </select>
                        <button onClick={adicionarEquipamento}>Adicionar Equipamento</button>
                    </div>
                    {adicionarMensagem && <p>{adicionarMensagem}</p>}
                </div>
            )}

            {/* Criar Ordem de Serviço */}
            {selectedEquipamento && (
                <div className="ordem-section">
                    <h4>Criar Ordem de Serviço para {equipamentos.find(e => e.id === selectedEquipamento)?.tipo_equipamento}</h4>
                    <input 
                        type="text" 
                        placeholder="Defeito Reclamado" 
                        value={ordemData.defeito_reclamado} 
                        onChange={(e) => setOrdemData({ ...ordemData, defeito_reclamado: e.target.value })} 
                    />
                    <input 
                        type="text" 
                        placeholder="Provável Causa (opcional)" 
                        value={ordemData.provavel_causa} 
                        onChange={(e) => setOrdemData({ ...ordemData, provavel_causa: e.target.value })} 
                    />
                    <input 
                        type="text" 
                        placeholder="Orçamento (R$)" 
                        value={ordemData.orcamento ? `R$ ${ordemData.orcamento}` : ''} 
                        onChange={handleOrcamentoChange} 
                    />
                    <DatePicker
                        selected={ordemData.data_entrada}
                        onChange={(date) => setOrdemData({ ...ordemData, data_entrada: date })}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Data da Ordem"
                    />
                    <DatePicker
                        selected={ordemData.data_saida}
                        onChange={(date) => setOrdemData({ ...ordemData, data_saida: date })}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Data de Saída (opcional)"
                    />
                    <textarea
                        placeholder="Fotos (opcional)"
                        value={ordemData.fotos || ''}
                        onChange={(e) => setOrdemData({ ...ordemData, fotos: e.target.value })}
                    />
                    <select 
                        value={ordemData.status} 
                        onChange={(e) => setOrdemData({ ...ordemData, status: e.target.value })}
                    >
                        <option value="Em análise">Em análise</option>
                        <option value="Aguardando aprovação">Aguardando aprovação</option>
                        <option value="Aprovado">Aprovado</option>
                        <option value="Reprovado">Reprovado</option>
                    </select>
                    <textarea 
                        placeholder="Observações (opcional)" 
                        value={ordemData.observacoes} 
                        onChange={(e) => setOrdemData({ ...ordemData, observacoes: e.target.value })}
                    />
                    <button onClick={criarOrdem}>Criar Ordem de Serviço</button>
                </div>
            )}
        </div>
    );
}

export default GerenciarEquipamentos;
