import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './GlobalStyles.css';

const API_URL = process.env.REACT_APP_API_URL;

const DashboardCliente = () => {
  const [cliente, setCliente] = useState(null);
  const [equipamentoAberto, setEquipamentoAberto] = useState(null); // Estado para controle de equipamento aberto
  const [ordens, setOrdens] = useState([]); // Estado para armazenar as ordens
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDadosCliente = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        navigate('/'); // Redireciona para o login se não houver token
        return; // Adicionando return para evitar execução adicional
      }

      try {
        // Faz uma requisição autenticada com o token para pegar os dados do cliente
        const response = await axios.get(`${API_URL}/api/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
        setCliente(response.data);
      } catch (err) {
        localStorage.removeItem('token'); // Remove o token se inválido
        navigate('/');
      }
    };

    fetchDadosCliente();
  }, [navigate]);

  const toggleEquipamento = async (equipamentoId) => {
    if (equipamentoAberto === equipamentoId) {
      setEquipamentoAberto(null); // Fecha se já está aberto
      setOrdens([]); // Limpa as ordens
    } else {
      setEquipamentoAberto(equipamentoId);
      // Faz uma requisição para buscar as ordens do equipamento
      try {
        const response = await axios.get(`${API_URL}/api/equipamentos/${equipamentoId}/ordens`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setOrdens(response.data);
      } catch (err) {
        console.error('Erro ao buscar ordens:', err);
      }
    }
  };

  const mostrarDetalhesOrdem = (ordem) => {
    return (
      <div className="order-details">
        <strong>Data de Entrada:</strong> {new Date(ordem.data_entrada).toLocaleDateString('pt-BR')} <br />
        <strong>Provável Causa:</strong> {ordem.provavel_causa} <br />
        <strong>Orçamento:</strong> {ordem.orcamento} <br />
        <strong>Status:</strong> <span style={{ color: 'red' }}>{ordem.status}</span> <br />
        <strong>Observações:</strong> {ordem.observacoes} <br />
      </div>
    );
  };

  if (!cliente) {
    return <p>Carregando...</p>; // Exibe enquanto os dados não são carregados
  }

  return (
    <div className="container">
      <div className="box">
        <h1 className="title">Bem-vindo, {cliente.nome}</h1>
        <h2 className="subtitle">Seus Equipamentos</h2>
        <ul className="equipment-list">
          {cliente.equipamentos.map((equipamento) => (
            <li key={equipamento.id} className="equipment-item">
              <button 
                className="button" 
                onClick={() => toggleEquipamento(equipamento.id)}
              >
                {equipamento.tipo_equipamento}
              </button>
              {equipamentoAberto === equipamento.id && (
                <div className="equipment-details">
                  <h3>Ordens:</h3>
                  {ordens.length > 0 ? (
                    ordens.map((ordem) => (
                      <div key={ordem.id} className="order-item">
                        <h4>Ordem #{ordem.id}</h4>
                        {mostrarDetalhesOrdem(ordem)}
                      </div>
                    ))
                  ) : (
                    <p>Nenhuma ordem encontrada para este equipamento.</p>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardCliente;
