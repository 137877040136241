// src/components/AdminDashboard.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './DashboardAdmin.css';
import CadastroCliente from './CadastroCliente'; // Assumindo que já foi criado
import GerenciarEquipamentos from './GerenciarEquipamentos'; // Novo componente
import Relatorios from './Relatorios'; // Novo componente

const AdminDashboard = () => {
  const [activePage, setActivePage] = useState('cadastro');

  const renderPage = () => {
    switch (activePage) {
      case 'cadastro':
        return <CadastroCliente />;
      case 'equipamentos':
        return <GerenciarEquipamentos />;
      case 'relatorios':
        return <Relatorios />;
      default:
        return <CadastroCliente />;
    }
  };

  return (
    <div className="admin-dashboard">
      <Sidebar setActivePage={setActivePage} />
      <div className="dashboard-content">
        {renderPage()}
      </div>
    </div>
  );
};

export default AdminDashboard;
